import { Formatter, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCardSize,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { ImageGallery } from "@with-nx/simple-ui/organisms";
import { Products, Product } from "libs/hooks-n-helpers/src/types";
import { FC, ReactNode } from "react";
import { FiPhone } from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

interface LatestDigitalDropsProps {
  title?: ReactNode;
}

export const LatestDigitalDrops: FC<LatestDigitalDropsProps> = ({ title }) => {
  const mobile = useMobile();

  return (
    <Box parse={`mb:${mobile ? 48 : 96} ta:center`}>
      {title || (
        <>
          <Box>
            <Rule parse="!dm d:block mb:12 ta:center" weight="700" tag="span">
              Discover New Digital Drops
            </Rule>
            <Rule parse="!_p d:block mb:20 ta:center c:?font3">
              With 3,000 animated drops at your fingertips,{" "}
              {mobile ? <br /> : undefined} curate the show collection that
              unlocks your creativity
            </Rule>
          </Box>
          <Box css="d:flex a:flex-start j:center a:center">
            <Box parse="mr:12 mb:12">
              <DesignedButton
                icon={
                  <FiPhone
                    size={20}
                    color="var(--primary)"
                    title="phone icon"
                  />
                }
                label="Schedule Consultation"
                theme="tetriary"
                href="/contact/calendar"
              />
            </Box>
            <Box parse="mr:12 mb:12">
              <DesignedButton
                label="View All"
                theme="primary"
                href="/digital-backdrops"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
